
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        


































































































.configurator-items__items {
  display: flex;

  ::v-deep .selectable-block__check {
    display: none;
  }
}

.configurator-items__items__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0.5rem;

  @include mq(m) {
    flex-direction: row;
    margin-top: 1rem;
    margin-right: 1rem;
    padding: 1.2rem 1rem;

    ::v-deep .selectable-block__check {
      margin-right: 0;
      margin-left: auto;
    }
  }
}

.configurator-items__items__item__title {
  @extend %fw-bold;

  font-size: 1.6rem;
  line-height: 2rem;
  text-transform: uppercase;

  @include mq(m) {
    margin: 0;
    text-transform: none;
  }
}
